import React from 'react'
import {Link} from "gatsby"
import moment from "moment"
import "moment/locale/de"
import time_icon from "../../static/images/icons/time.png"

moment.locale('de');

class PostPreview extends React.Component {
    render() {
        const {title, description, path} = this.props.data.node.frontmatter;
        const image = this.props.data.node.frontmatter.image.childImageSharp.resize.src;
        const timeToRead = this.props.data.node.timeToRead;
        const date = this.props.data.node.frontmatter.date;
        const maxLength = 152;

        let postPreviewImageStyle = {
            backgroundImage: 'url(' + image + ')',
            minHeight: '260px',
            backgroundSize: '400px',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat'
        };

        return (
            <div className="PostPreview-wrapper">
                <Link to={path}>
                    <div style={postPreviewImageStyle} className="lazyload" loading="lazy"> </div>
                </Link>
                <div className="PostPreview-text-container">

                    <div className="PostPreview-inner">
                        <div className="PostPreview-details">
                            <small className="PostPreview-details__date">
                                {moment(date).format("DD.MM.YYYY")}</small>
                            <small>
                                {timeToRead} MIN <img src={time_icon} alt="Lesezeit" className="PostPreview-icon"/>
                            </small>
                        </div>

                        <Link to={path}>
                            <h2 className="PostPreview-title">{title}</h2>
                        </Link>
                        <p className="PostPreview-description">{description.substring(0, maxLength)} ...</p>
                    </div>
                    <Link to={path} className="button orange">weiterlesen</Link>
                </div>
            </div>
        );
    }
}

export default PostPreview;
