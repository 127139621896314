import React from "react";
import {Link} from "gatsby";
import holisent_logo from "../../static/holisent_logo.svg";
import holisent_logo_small from "../../static/holisent_logo_small.svg";
import NavigationItem from "./NavigationItem";


class MainNav extends React.Component {
    render() {
        const navigationItemOrangeHighlight = "Navigation-list-orange-highlight";
        return (
            <React.Fragment>
                <Link to="/">
                    <img src={holisent_logo} alt="HOLISENT Logo"
                         className="Navigation-logo-big"/>
                    <img src={holisent_logo_small} alt="HOLISENT Logo"
                         className="Navigation-logo-small"
                    />
                </Link>

                <ul className="Navigation-list">
                    <li>
                        <NavigationItem
                            activeClassName={navigationItemOrangeHighlight}
                            to="/ideen"
                        >
                            Ideen
                        </NavigationItem>
                    </li>
                    <li>
                        <NavigationItem
                            activeClassName={navigationItemOrangeHighlight}
                            to="/priorisierung">
                            Priorisierung
                        </NavigationItem>
                    </li>
                    <li>
                        <NavigationItem
                            activeClassName={navigationItemOrangeHighlight}
                            to="/storymap">
                            Storymap
                        </NavigationItem>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}

export default MainNav