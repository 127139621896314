import React from "react"
import CookiePopUp from "react-cookie-consent"
import Cookie from "react-cookies"
import { Link } from "gatsby"

/* eslint-disable */

class CookieConsent extends React.Component {
    constructor() {
        super();
        this.removeCookieConsent.bind(this);
        this.state = {
            display: true
        };
        this.removeCookieConsent = this.removeCookieConsent.bind(this);
    }

    componentDidMount() {
        this.checkIfOptOutExists();
    }

    removeCookieConsent() {
        Cookie.save('ga-disable-UA-159671235-1', true);
        this.setState({display: !this.state.display})
    }

    checkIfOptOutExists() {
        if (Cookie.load('ga-disable-UA-159671235-1')) {
            this.removeCookieConsent();
        } else {
            this.setState({display: this.state.display});
        }
    }

    render() {
        let displayState = this.state.display ? "block" : "none";

        return (
            <div style={{display: displayState}}>
                <CookiePopUp displayState={displayState}
                             location="bottom"
                             buttonText="Akzeptieren"
                             containerClasses="CookiePopUp-content"
                             buttonClasses="CookiePopUp-button"
                             disableButtonStyles={true}
                >
                    Diese Webseite verwendet Cookies und Google Analytics, um die Inhalte zu optimieren und zu verbessern.
                    Sie können der Verwendung von Google Analytics zustimmen oder <a
                    href="javascript:gaOptout();" className="CookiePopUp-link"
                    onClick={this.removeCookieConsent}>ablehnen.</a><br/>
                    <small>
                        Weitere Informationen hierzu erhalten Sie in unserer <Link className="CookiePopUp-link" to="/impressum">Datenschutzerklärung</Link>.
                    </small>
                </CookiePopUp>
            </div>
        )
    }
}

export default CookieConsent
