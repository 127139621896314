import React from "react";
import MainNav from "./MainNav";
import PropTypes from "prop-types";

export default class Navigation extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className="Navigation-container">
                <MainNav/>
            </div>
        );
    }
}