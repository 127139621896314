import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Link} from "gatsby";

const NAV_ITEM_ANCHOR_OFFSET = "110";

export default class NavigationItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false
        }
    }

    render() {
        const {activeClassName, anchorItem, rel, target, to} = this.props;
        const {isHovered} = this.state;

        return anchorItem ? (
            <AnchorLink offset={NAV_ITEM_ANCHOR_OFFSET}
                        href={to}
                        className={isHovered ? activeClassName : null}
                        onMouseEnter={() => this.setState({isHovered: true})}
                        onMouseLeave={() => this.setState({isHovered: false})}
            >
                {this.props.children}
            </AnchorLink>
        ) : (
            <Link activeClassName={activeClassName}
                  className={isHovered ? activeClassName : null}
                  onMouseEnter={() => this.setState({isHovered: true})}
                  onMouseLeave={() => this.setState({isHovered: false})}
                  to={to}
                  target={target}
                  rel={rel}
            >
                {this.props.children}
            </Link>
        );
    }
}