import React from "react";
import {Link} from "gatsby";

const Footer = () => (
    <div>
        <div className="Footer-container">
            <Link to="/">Start</Link><br/>
            <Link to="/ideen">Ideen</Link><br/>
            <Link to="/priorisierung">Priorisierung</Link><br/>
            <Link to="/storymap">Storymap</Link><br/>
            <hr className="Footer-line"/>
            <Link to="/ueber-mich">Über mich</Link><br/>
            <Link to="/impressum">Kontakt, Impressum, Datenschutz</Link>
        </div>

        <div className="Footer-end">
            <span style={{fontFamily: 'Arial'}}>©</span> Erik Möser | HOLISENT {new Date().getFullYear()}<br/>
        </div>
    </div>
);

export default Footer