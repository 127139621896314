import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import Header from "./Header/index";
import Footer from "./Footer/index";
import CookiePopUp from "./CookiePopUp";
import "../styles/init.scss";

const Layout = ({children, location}) => (
    <div>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>HOLISENT</title>
            <meta name="description" content="Beschreibung"/>
            <meta name="keywords" content="Keywords"/>
        </Helmet>
        <Header location={location}/>
        <div className="Layout-master-wrapper">
            <CookiePopUp/>
            {children}
        </div>
        <Footer/>
    </div>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout