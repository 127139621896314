import React from "react"
import Navigation from "./Navigation"
import {Location} from "@reach/router"
import Headroom from "react-headroom"

const Header = () => (
    <Location>
        {({location}) => (
            <Headroom>
                <div className="Header">
                    <Navigation location={location}/>
                </div>
            </Headroom>
        )}
    </Location>
);

export default Header